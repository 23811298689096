.job-search-bar {
  
}

.search-bar-box {
  padding: 20px 30px;
}

.search-bar-title {
  font-weight: bold;
}

.query-area {
  display: inline-block;
  width: 100%;
}

.search-field {
  /* display: inline-block; */
  /* width: calc(100% - 115px); */
  width: 100%;
  margin-right: 15px;
  float: left;
}

.search-btn {
  /* display: inline-block; */
  width: 100px;
  float: right;
}


.filter-badges {
  margin-top: 25px;
}

.filter-badge {
  background: #e2e2e2;
  color: #333;
  pointer-events: none;
}

.filter-remove {
  cursor: pointer;
  pointer-events: auto;
}

.filter-badge:hover {
  background: #333;
  color: #fff;
  transition: 0.3s ease;
}

.filter-container {
  margin-bottom: -5px;;
}

@media (max-width: 992px) {
  .filter-container {
    margin-bottom: 7px;
  }
}


@media (max-width: 500px) {
  .search-field {
    display: block;
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }
  .search-btn {
    display: block;
    width: 100%;
    float: none;
  }
}

.lander-search-field {
  border-radius: 4px !important;
  font-size: 1.1rem;
  height: 40px;
}

.lander-search-btn {
  border-radius: 4px !important;
  font-size: 1.1rem;
}

.lander-mobile-search-btn {
  display: none;
}

@media (max-width: 512px) {
  .lander-search-field {
    font-size: 1rem;
  }

  .lander-search-btn {
    font-size: 1rem;
    display: block;
  }

  .lander-append {
    display: none;
  }

  .lander-mobile-search-btn {
    display: block;
    margin-top: 10px;
  }
}